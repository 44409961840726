import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import USER_INFO from '@/components/backend/graphql/UserInfo.graphql';
import Auth from '@/auth';
let App = class App extends Vue {
    created() {
        this.$apollo.query({
            query: USER_INFO,
            variables: {
                id: Auth.getUserId(),
            },
        }).then((response) => {
            this.$store.commit('SET_USER_INFO', response.data.user);
            switch (this.$route.query.modal) {
                case 'password-reset-complete':
                    this.$store.commit('OPEN_MODAL', { name: 'message', options: { text: 'Восстановление пароля завершено!' } });
                    break;
                case 'password-reset':
                    this.$store.commit('OPEN_MODAL', { name: 'password-reset' });
                    break;
                case 'registration-succeed':
                    this.$store.commit('OPEN_MODAL', { name: 'message', options: { text: 'Регистрация завершена!' } });
                    break;
                case 'verification-mail-sent':
                    this.$store.commit('OPEN_MODAL', { name: 'message', options: { text: 'Ссылка для продолжения регистрации отправлена на ваш email!' } });
                    break;
                case 'auth':
                    this.$store.commit('OPEN_MODAL', { name: 'auth' });
                    break;
            }
        });
    }
    onFinishLoading() {
        // console.log(1);
    }
};
__decorate([
    Watch('$apollo.loading')
], App.prototype, "onFinishLoading", null);
App = __decorate([
    Component
], App);
export default App;
