const frontendRestaurantPage = () => import('@/components/frontend/pages/Restaurant/RestaurantPage.vue');
const frontendRestaurantsPage = () => import('@/components/frontend/pages/Restaurant/RestaurantsPage.vue');
export default [
    {
        path: '/restaurants',
        name: 'front-restaurants',
        component: frontendRestaurantsPage,
        meta: { advertisement_placement_id: 13 },
    },
    {
        path: '/restaurant/:id',
        name: 'front-restaurant',
        component: frontendRestaurantPage,
        meta: { advertisement_placement_id: 13 },
    },
];
