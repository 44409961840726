import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
// @ts-ignore
import { createProvider } from './vue-apollo';
import VueObserveVisibility from 'vue-observe-visibility';
// @ts-ignore
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import moment from 'vue-moment';
// @ts-ignore
import VueTheMask from 'vue-the-mask';
// @ts-ignore
import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/mdn-like.css';
import VueEasyLightbox from 'vue-easy-lightbox';
Vue.use(VueEasyLightbox);
Vue.use(VueTheMask);
Vue.use(VueQuillEditor);
Vue.use(VueObserveVisibility);
Vue.use(moment);
Vue.use(VueCodemirror, {
    tabSize: 4,
    mode: 'text/html',
    theme: 'base16-light',
    lineWrapping: true,
    lineNumbers: true,
    line: true,
});
Vue.config.productionTip = true;
new Vue({
    router,
    store,
    apolloProvider: createProvider(),
    render: (h) => h(App),
}).$mount('#app');
