const frontendBookmakersOverviewPage = () => import('@/components/frontend/pages/Bookmakers/BookmakersOverviewPage.vue');
const frontendBookmakersTopPage = () => import('@/components/frontend/pages/Bookmakers/BookmakersTopPage.vue');
const frontendBookmakersRatingPage = () => import('@/components/frontend/pages/Bookmakers/BookmakersRatingPage.vue');
const frontendBookmakersSalePage = () => import('@/components/frontend/pages/Bookmakers/BookmakersSalePage.vue');
const frontendBookmakerPage = () => import('@/components/frontend/pages/Bookmakers/BookmakerPage.vue');
const frontendBookmakersPageNews = () => import('@/components/frontend/pages/Bookmakers/BookmakersPageNews.vue');
const frontendBookmakersPageSale = () => import('@/components/frontend/pages/Bookmakers/BookmakersPageSale.vue');
const frontendBookmakersPageReviews = () => import('@/components/frontend/pages/Bookmakers/BookmakersPageReviews.vue');
const frontendBookmakersPageContacts = () => import('@/components/frontend/pages/Bookmakers/BookmakersPageContacts.vue');
export default [
    {
        path: '/bookmakers',
        name: 'front-bookmakers-page',
        component: frontendBookmakersOverviewPage,
        meta: { advertisement_placement_id: 4 },
    },
    {
        path: '/bookmakers/overview',
        name: 'front-bookmakers-overview-page',
        component: frontendBookmakersOverviewPage,
        meta: { advertisement_placement_id: 4 },
    },
    {
        path: '/bookmakers/overview/:id',
        name: 'front-bookmakers-news-item',
        component: frontendBookmakerPage,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 12 },
        // meta: { menu: 'HeaderBookmakersMenu', advertisement_placement_id: 12 },
    },
    {
        path: '/bookmakers/overview/:id/news',
        name: 'front-bookmakers-news',
        component: frontendBookmakersPageNews,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 12 },
        // meta: { menu: 'HeaderBookmakersMenu', advertisement_placement_id: 12 },
    },
    {
        path: '/bookmakers/overview/:id/sale',
        name: 'front-bookmakers-sale',
        component: frontendBookmakersPageSale,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 12 },
        // meta: { menu: 'HeaderBookmakersMenu', advertisement_placement_id: 12 },
    },
    {
        path: '/bookmakers/overview/:id/reviews',
        name: 'front-bookmakers-reviews',
        component: frontendBookmakersPageReviews,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 12 },
        // meta: { menu: 'HeaderBookmakersMenu', advertisement_placement_id: 12 },
    },
    {
        path: '/bookmakers/overview/:id/contacts',
        name: 'front-bookmakers-contacts',
        component: frontendBookmakersPageContacts,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 12 },
        // meta: { menu: 'HeaderBookmakersMenu', advertisement_placement_id: 12 },
    },
    {
        path: '/bookmakers/top',
        name: 'front-bookmakers-top-page',
        component: frontendBookmakersTopPage,
        meta: { advertisement_placement_id: 4 },
    },
    {
        path: '/bookmakers/rating',
        name: 'front-bookmakers-rating-page',
        component: frontendBookmakersRatingPage,
        meta: { advertisement_placement_id: 4 },
    },
    {
        path: '/bookmakers/sale',
        name: 'front-bookmakers-sale-page',
        component: frontendBookmakersSalePage,
        meta: { advertisement_placement_id: 4 },
    },
];
