const frontendHotelsPage = () => import('@/components/frontend/pages/Hotel/HotelsPage.vue');
const frontendHotelPage = () => import('@/components/frontend/pages/Hotel/HotelPage.vue');
export default [
    {
        path: '/hotels',
        name: 'front-hotels',
        meta: { advertisement_placement_id: 17 },
        component: frontendHotelsPage,
    },
    {
        path: '/hotel/:id',
        name: 'front-hotel',
        meta: { advertisement_placement_id: 15 },
        component: frontendHotelPage,
    },
];
