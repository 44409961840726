import Vue from 'vue';
import Vuex from 'vuex';
// @ts-ignore:
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);
Vue.use(Vuex);
const store = {
    state: {
        loading: true,
        references: {
            countryDropdownOptions: {
                0: 'Все страны',
                1: 'Северный Кипр',
                2: 'Грузия',
                3: 'Казахстан',
                4: 'Украина',
                5: 'Белоруссия',
                6: 'Россия',
                7: 'Китай',
            },
            gameTypeDropdownOptions: {
                0: 'Тип игры',
                1: 'Все',
                2: 'Рулетка',
                3: 'Покер',
                4: 'Блэкджек',
                5: 'Баккара',
            },
            languageDropdownOptions: {
                0: 'Любой язык',
                1: 'Русский',
                2: 'Белоруский',
                3: 'Английский',
            },
        },
        user: {
            id: 0,
            username: '',
            email: '',
            email_confirmed: '',
            password: '',
            person: {
                firstname: '',
                lastname: '',
                phone: '',
                vk: '',
                linkedin: '',
                fb: '',
                twitter: '',
                gender: '',
                birthday: '',
                instagram: '',
                description: '',
                photo_id: 0,
                photo: {
                    path: '',
                    type: 0,
                },
            },
            company: {
                name: '',
                operating_hours: '',
                phone: '',
                country: 0,
                city: '',
                address: '',
                website: '',
                vk: '',
                linkedin: '',
                fb: '',
                twitter: '',
                instagram: '',
                description: '',
                photo_id: 0,
                photo: {
                    path: '',
                    type: 0,
                },
                lat: 0,
                long: 0,
                area: 0,
            },
            type: '',
            type_id: 0,
            created_at: '',
        },
        loaded: false,
        modal: '',
        modalOptions: {},
        lightbox: false,
        lightboxIndex: 0,
        lightboxList: [],
        mobileMenu: false,
    },
    mutations: {
        SET_LOADING(state, value) {
            state.loading = value;
        },
        SET_USER_INFO(state, value) {
            state.user = value;
            state.loaded = true;
        },
        OPEN_MODAL(state, value) {
            window.document.querySelector('body').classList.add('modal-open');
            state.modal = value.name;
            state.modalOptions = value.options;
        },
        CLOSE_MODAL(state) {
            setTimeout(() => {
                window.document.querySelector('body').classList.remove('modal-open');
                state.modal = '';
                state.modalOptions = {};
            });
        },
        OPEN_LIGHTBOX(state, value) {
            state.lightbox = true;
            state.lightboxList = value.items;
            state.lightboxIndex = value.index;
        },
        MOBILE_MENU(state, value) {
            if (value) {
                window.document.querySelector('body').classList.add('add--body-open-menu');
            }
            else {
                window.document.querySelector('body').classList.remove('add--body-open-menu');
            }
            state.mobileMenu = value;
        },
        CLOSE_LIGHTBOX(state) {
            state.lightbox = false;
        },
    },
};
export default new Vuex.Store(store);
