const companyLayout = () => import('@/components/backend/layouts/CompanyLayout.vue');
const backendCommonPage = () => import('@/components/backend/pages/default/CommonPage.vue');
const backendCasinoPage = () => import('@/components/backend/pages/casino/CasinoPage.vue');
const backendCasinoNewsPage = () => import('@/components/backend/pages/casino/CasinoNewsPage.vue');
const backendCasinoNewsListPage = () => import('@/components/backend/pages/casino/CasinoNewsListPage.vue');
const backendCasinoCalendarPage = () => import('@/components/backend/pages/casino/CasinoCalendarPage.vue');
const backendCasinoGameTourPage = () => import('@/components/backend/pages/casino/CasinoGameTourPage.vue');
const backendCasinoGameVipTourPage = () => import('@/components/backend/pages/casino/CasinoGameVipTourPage.vue');
const backendCasinoMessagesPage = () => import('@/components/backend/pages/casino/CasinoMessagesPage.vue');
const backendCasinoOffersPage = () => import('@/components/backend/pages/casino/CasinoOffersPage.vue');
const backendEstimatesPage = () => import('@/components/backend/pages/casino/EstimatesPage.vue');
const backendFollowersPage = () => import('@/components/backend/pages/casino/FollowersPage.vue');
const backendHotelPage = () => import('@/components/backend/pages/casino/HotelPage.vue');
const backendPokerPage = () => import('@/components/backend/pages/casino/PokerPage.vue');
const backendRafflePage = () => import('@/components/backend/pages/casino/RafflePage.vue');
const backendRestaurantPage = () => import('@/components/backend/pages/casino/RestaurantPage.vue');
const backendReviewsPage = () => import('@/components/backend/pages/casino/ReviewsPage.vue');
const backendSportBettingPage = () => import('@/components/backend/pages/casino/SportBettingPage.vue');
const backendSubscribitionsPage = () => import('@/components/backend/pages/casino/SubscribitionsPage.vue');
const backendCompanyChatListPage = () => import('@/components/backend/pages/casino/ChatListPage.vue');
const backendCompanyChatPage = () => import('@/components/backend/pages/casino/ChatPage.vue');
const backendStatisticsPage = () => import('@/components/backend/pages/default/StatisticsPage.vue');
export default {
    path: '/company',
    component: companyLayout,
    children: [
        {
            path: '/company/casino',
            name: 'company-casino-profile-index',
            component: backendCasinoPage,
        }, {
            path: '/company/profile',
            name: 'company-profile-index',
            component: backendCommonPage,
        }, {
            path: '/company/news',
            name: 'company-profile-news',
            component: backendCasinoNewsPage,
        }, {
            path: '/company/news-list',
            name: 'company-profile-news-list',
            component: backendCasinoNewsListPage,
        }, {
            path: '/company/tours',
            name: 'company-profile-tours',
            component: backendCasinoGameTourPage,
        }, {
            path: '/company/vip-tours',
            name: 'company-profile-vip-tours',
            component: backendCasinoGameVipTourPage,
        }, {
            path: '/company/hotel',
            name: 'company-profile-hotel',
            component: backendHotelPage,
        }, {
            path: '/company/restaurant',
            name: 'company-profile-restaurant',
            component: backendRestaurantPage,
        }, {
            path: '/company/poker',
            name: 'company-profile-poker',
            component: backendPokerPage,
        }, {
            path: '/company/sports-betting',
            name: 'company-profile-sports-betting',
            component: backendSportBettingPage,
        }, {
            path: '/company/raffle',
            name: 'company-profile-raffle',
            component: backendRafflePage,
        }, {
            path: '/company/offers',
            name: 'company-profile-list',
            component: backendCasinoOffersPage,
        }, {
            path: '/company/calendar',
            name: 'company-profile-calendar',
            component: backendCasinoCalendarPage,
        }, {
            path: '/company/reviews',
            name: 'company-profile-reviews',
            component: backendReviewsPage,
        }, {
            path: '/company/estimates',
            name: 'company-profile-estimates',
            component: backendEstimatesPage,
        }, {
            path: '/company/followers',
            name: 'company-profile-followers',
            component: backendFollowersPage,
        }, {
            path: '/company/subscribitions',
            name: 'company-profile-subscribitions',
            component: backendSubscribitionsPage,
        }, {
            path: '/company/chat',
            name: 'company-profile-chat-list',
            component: backendCompanyChatListPage,
        }, {
            path: '/company/chat/:senderId',
            name: 'company-profile-chat',
            component: backendCompanyChatPage,
        }, {
            path: '/company/statistics',
            name: 'company-statistics',
            component: backendStatisticsPage,
        },
    ],
};
