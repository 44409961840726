const frontendCalendarPage = () => import('@/components/frontend/pages/Calendar/CalendarPage.vue');
const frontendCalendarEventPage = () => import('@/components/frontend/pages/Calendar/CalendarEventPage.vue');
export default [
    {
        path: '/calendar/',
        name: 'front-calendar-page',
        component: frontendCalendarPage,
        meta: { advertisement_placement_id: 6 },
    },
    {
        path: '/calendar/:id',
        name: 'front-calendar-event-page',
        component: frontendCalendarEventPage,
        meta: { advertisement_placement_id: 16 },
    },
];
