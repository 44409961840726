const frontendGameToursPage = () => import('@/components/frontend/pages/GameTours/GameToursPage.vue');
const frontendGameToursPersonalPage = () => import('@/components/frontend/pages/GameTours/GameToursPersonalPage.vue');
const frontendGameToursVipPage = () => import('@/components/frontend/pages/GameTours/GameToursVipPage.vue');
const frontendGameToursPopularPage = () => import('@/components/frontend/pages/GameTours/GameToursPopularPage.vue');
const frontendGameToursFavoritePage = () => import('@/components/frontend/pages/GameTours/GameToursFavoritePage.vue');
const frontendGameToursFavoriteDirectionPage = () => import('@/components/frontend/pages/GameTours/GameToursFavoriteDirectionPage.vue');
const frontendGameTourPage = () => import('@/components/frontend/pages/GameTours/GameTourPage.vue');
export default [
    {
        path: '/game-tours',
        name: 'front-game-tours',
        component: frontendGameToursPage,
        meta: { advertisement_placement_id: 0 },
    },
    {
        path: '/game-tours/personal',
        name: 'front-game-tours-personal',
        component: frontendGameToursPersonalPage,
        meta: { advertisement_placement_id: 0 },
    },
    {
        path: '/game-tours/vip',
        name: 'front-game-tours-vip',
        component: frontendGameToursVipPage,
        meta: { advertisement_placement_id: 0 },
    },
    {
        path: '/game-tours/popular',
        name: 'front-game-tours-popular',
        component: frontendGameToursPopularPage,
        meta: { advertisement_placement_id: 0 },
    },
    {
        path: '/game-tours/favorite',
        name: 'front-game-tours-favorite',
        component: frontendGameToursFavoritePage,
        meta: { advertisement_placement_id: 0 },
    },
    {
        path: '/game-tours/favorite/:id',
        name: 'front-game-tours-favorite-direction',
        component: frontendGameToursFavoriteDirectionPage,
        meta: { advertisement_placement_id: 0 },
    },
    {
        path: '/game-tour/:id',
        name: 'front-game-tour',
        component: frontendGameTourPage,
        meta: { advertisement_placement_id: 0 },
    },
];
