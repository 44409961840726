const frontendOnlinePage = () => import('@/components/frontend/pages/Online/OnlinePage.vue');
const frontendOnlineTopPage = () => import('@/components/frontend/pages/Online/OnlineTopPage.vue');
const frontendOnlinePersonalPage = () => import('@/components/frontend/pages/Online/OnlinePersonalPage.vue');
const frontendOnlineOverviewPage = () => import('@/components/frontend/pages/Online/OnlineOverviewPage.vue');
const frontendOnlineRatingPage = () => import('@/components/frontend/pages/Online/OnlineRatingPage.vue');
const frontendOnlinePokerTopPage = () => import('@/components/frontend/pages/Online/OnlinePokerTopPage.vue');
const frontendOnlinePokerPersonalPage = () => import('@/components/frontend/pages/Online/OnlinePokerPersonalPage.vue');
const frontendOnlinePokerOverviewPage = () => import('@/components/frontend/pages/Online/OnlinePokerOverviewPage.vue');
const frontendOnlinePokerRatingPage = () => import('@/components/frontend/pages/Online/OnlinePokerRatingPage.vue');
const frontendOnlineSportTopPage = () => import('@/components/frontend/pages/Online/OnlineSportTopPage.vue');
const frontendOnlineSportPersonalPage = () => import('@/components/frontend/pages/Online/OnlineSportPersonalPage.vue');
const frontendOnlineSportOverviewPage = () => import('@/components/frontend/pages/Online/OnlineSportOverviewPage.vue');
const frontendOnlineSportRatingPage = () => import('@/components/frontend/pages/Online/OnlineSportRatingPage.vue');
export default [
    {
        path: '/online/',
        name: 'front-online-page',
        component: frontendOnlinePage,
    },
    {
        path: '/online/top',
        name: 'front-online-top-page',
        component: frontendOnlineTopPage,
    },
    {
        path: '/online/personal',
        name: 'front-online-personal-page',
        component: frontendOnlinePersonalPage,
    },
    {
        path: '/online/overview',
        name: 'front-online-overview-page',
        component: frontendOnlineOverviewPage,
    },
    {
        path: '/online/rating',
        name: 'front-online-rating-page',
        component: frontendOnlineRatingPage,
    },
    {
        path: '/online/poker/top',
        name: 'front-online-poker-top-page',
        component: frontendOnlinePokerTopPage,
    },
    {
        path: '/online/poker/personal',
        name: 'front-online-poker-personal-page',
        component: frontendOnlinePokerPersonalPage,
    },
    {
        path: '/online/poker/overview',
        name: 'front-online-poker-overview-page',
        component: frontendOnlinePokerOverviewPage,
    },
    {
        path: '/online/poker/rating',
        name: 'front-online-poker-rating-page',
        component: frontendOnlinePokerRatingPage,
    },
    {
        path: '/online/sport/top',
        name: 'front-online-sport-top-page',
        component: frontendOnlineSportTopPage,
    },
    {
        path: '/online/sport/personal',
        name: 'front-online-sport-personal-page',
        component: frontendOnlineSportPersonalPage,
    },
    {
        path: '/online/sport/overview',
        name: 'front-online-sport-overview-page',
        component: frontendOnlineSportOverviewPage,
    },
    {
        path: '/online/sport/rating',
        name: 'front-online-sport-rating-page',
        component: frontendOnlineSportRatingPage,
    },
];
