const frontendExpertsTopPage = () => import('@/components/frontend/pages/Experts/ExpertsTopPage.vue');
const frontendExpertsOverviewPage = () => import('@/components/frontend/pages/Experts/ExpertsOverviewPage.vue');
const frontendExpertsRatingPage = () => import('@/components/frontend/pages/Experts/ExpertsRatingPage.vue');
export default [
    {
        path: '/experts',
        name: 'front-experts',
        component: frontendExpertsTopPage,
        meta: { advertisement_placement_id: 5 },
    },
    {
        path: '/experts/top',
        name: 'front-experts-top',
        component: frontendExpertsTopPage,
        meta: { advertisement_placement_id: 5 },
    },
    {
        path: '/experts/overview',
        name: 'front-experts-overview',
        component: frontendExpertsOverviewPage,
        meta: { advertisement_placement_id: 5 },
    },
    {
        path: '/experts/rating',
        name: 'front-experts-rating',
        component: frontendExpertsRatingPage,
        meta: { advertisement_placement_id: 5 },
    },
];
