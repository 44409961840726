const frontendDirectionsPage = () => import('@/components/frontend/pages/Directions/DirectionsPage.vue');
const frontendDirectionPage = () => import('@/components/frontend/pages/Directions/DirectionPage.vue');
export default [
    {
        path: '/directions/',
        name: 'front-directions-page',
        component: frontendDirectionsPage,
        meta: { advertisement_placement_id: 23 },
    },
    {
        path: '/direction/:id',
        name: 'front-direction-page',
        component: frontendDirectionPage,
        meta: { advertisement_placement_id: 23 },
    },
];
