const adminLayout = () => import('@/components/backend/layouts/AdminLayout.vue');
const backendUserAdminPage = () => import('@/components/backend/pages/admin/UserPage.vue');
const backendUsersPage = () => import('@/components/backend/pages/admin/UsersPage.vue');
const backendEnquiriesPage = () => import('@/components/backend/pages/admin/EnquiriesPage.vue');
const backendArticlePage = () => import('@/components/backend/pages/admin/ArticlePage.vue');
const backendAdvertisementPage = () => import('@/components/backend/pages/admin/AdvertisementPage.vue');
const backendAdminChatListPage = () => import('@/components/backend/pages/admin/ChatListPage.vue');
const backendAdminChatPage = () => import('@/components/backend/pages/admin/ChatPage.vue');
const backendHelpDeskPage = () => import('@/components/backend/pages/admin/HelpDeskPage.vue');
export default {
    path: '/admin',
    component: adminLayout,
    children: [
        {
            path: '/admin',
            name: 'admin-index',
            component: backendUserAdminPage,
        }, {
            path: '/admin/users',
            name: 'admin-users',
            component: backendUsersPage,
            meta: {
            // requirePermission: 'viewUsersList',
            },
        }, {
            path: '/admin/enquiries',
            name: 'admin-users',
            component: backendEnquiriesPage,
            meta: {
            // requirePermission: 'viewUsersList',
            },
        }, {
            path: '/admin/user/:id',
            name: 'admin-user',
            component: backendUserAdminPage,
            meta: {
            // requirePermission: 'viewUser',
            },
        }, {
            path: '/admin/profile',
            name: 'admin-profile',
            component: backendUserAdminPage,
        }, {
            path: '/admin/additional',
            name: 'admin-additional',
            component: backendArticlePage,
        }, {
            path: '/admin/advertisements',
            name: 'admin-advertisement',
            component: backendAdvertisementPage,
        }, {
            path: '/admin/chat',
            name: 'admin-chat-list',
            component: backendAdminChatListPage,
        }, {
            path: '/admin/chat/:senderId',
            name: 'admin-chat',
            component: backendAdminChatPage,
        },
        {
            path: '/admin/helpdesk',
            name: 'admin-helpdesk',
            component: backendHelpDeskPage,
        },
    ]
};
