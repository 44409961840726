const backendLayout = () => import('@/components/backend/layouts/BaseLayout.vue');
const backendUserDefaultPage = () => import('@/components/backend/pages/default/UserPage.vue');
const backendUserSummaryPage = () => import('@/components/backend/pages/default/SummaryPage.vue');
const backendUserArticlesPage = () => import('@/components/backend/pages/default/ArticlesPage.vue');
const backendUserNewsPage = () => import('@/components/backend/pages/default/NewsPage.vue');
const backendUserToursPage = () => import('@/components/backend/pages/default/ToursPage.vue');
const backendUserTourPage = () => import('@/components/backend/pages/default/TourPage.vue');
const backendUserEstimatesPage = () => import('@/components/backend/pages/default/EstimatesPage.vue');
const backendUserReviewsPage = () => import('@/components/backend/pages/default/ReviewsPage.vue');
const backendUserMessagesPage = () => import('@/components/backend/pages/default/MessagesPage.vue');
const backendUserFollowersPage = () => import('@/components/backend/pages/default/FollowersPage.vue');
const backendUserSubscriptionsPage = () => import('@/components/backend/pages/default/SubscribtionsPage.vue');
const backendUserBusinessPage = () => import('@/components/backend/pages/default/BusinessPage.vue');
const backendUserChatListPage = () => import('@/components/backend/pages/default/ChatListPage.vue');
const backendUserChatPage = () => import('@/components/backend/pages/default/ChatPage.vue');
export default {
    path: '/default',
    component: backendLayout,
    children: [
        {
            path: '/default/summary',
            name: 'profile-summary',
            component: backendUserSummaryPage,
        },
        {
            path: '/default/profile',
            name: 'profile-profile',
            component: backendUserDefaultPage,
        },
        {
            path: '/default/articles',
            name: 'profile-articles',
            component: backendUserArticlesPage,
        },
        {
            path: '/default/news',
            name: 'profile-news',
            component: backendUserNewsPage,
        },
        {
            path: '/default/tours',
            name: 'profile-tours',
            component: backendUserToursPage,
        },
        {
            path: '/default/tours/:id',
            name: 'profile-tour',
            component: backendUserTourPage,
        },
        {
            path: '/default/reviews',
            name: 'profile-reviews',
            component: backendUserReviewsPage,
        },
        {
            path: '/default/estimates',
            name: 'profile-estimates',
            component: backendUserEstimatesPage,
        },
        {
            path: '/default/messages',
            name: 'profile-messages',
            component: backendUserMessagesPage,
        },
        {
            path: '/default/followers',
            name: 'profile-followers',
            component: backendUserFollowersPage,
        },
        {
            path: '/default/subscriptions',
            name: 'profile-subscriptions',
            component: backendUserSubscriptionsPage,
        },
        {
            path: '/default/business',
            name: 'profile-business',
            component: backendUserBusinessPage,
        },
        {
            path: '/default/chat',
            name: 'profile-chat-list',
            component: backendUserChatListPage,
        },
        {
            path: '/default/chat/:senderId',
            name: 'profile-chat',
            component: backendUserChatPage,
        },
    ],
};
