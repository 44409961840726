const frontendCasinoOverviewPage = () => import('@/components/frontend/pages/Casino/CasinoOverviewPage.vue');
const frontendCasinoTopPage = () => import('@/components/frontend/pages/Casino/CasinoTopPage.vue');
const frontendCasinoRatingPage = () => import('@/components/frontend/pages/Casino/CasinoRatingPage.vue');
const frontendCasinoSalePage = () => import('@/components/frontend/pages/Casino/CasinoSalePage.vue');
const frontendGameToursPage = () => import('@/components/frontend/pages/GameTours/GameToursPage.vue');
const frontendCasinoPage = () => import('@/components/frontend/pages/Casino/CasinoPage.vue');
const frontendCasinoMapPage = () => import('@/components/frontend/pages/Casino/CasinoMapPage.vue');
const frontendCasinoPageSale = () => import('@/components/frontend/pages/Casino/CasinoPageSale.vue');
const frontendCasinoPageCalendar = () => import('@/components/frontend/pages/Casino/CasinoPageCalendar.vue');
const frontendCasinoPageReviews = () => import('@/components/frontend/pages/Casino/CasinoPageReviews.vue');
const frontendCasinoPageContacts = () => import('@/components/frontend/pages/Casino/CasinoPageContacts.vue');
const frontendCasinoPageRestaurant = () => import('@/components/frontend/pages/Casino/CasinoPageRestaurant.vue');
const frontendCasinoPageHotel = () => import('@/components/frontend/pages/Casino/CasinoPageHotel.vue');
const frontendCasinoPageNews = () => import('@/components/frontend/pages/Casino/CasinoPageNews.vue');
const frontendCasinoNewsOnePage = () => import('@/components/frontend/pages/Casino/CasinoNewsOnePage.vue');
export default [
    {
        path: '/casino',
        name: 'front-casino-section',
        component: frontendCasinoOverviewPage,
        meta: { advertisement_placement_id: 2 },
    },
    {
        path: '/casino/overview',
        name: 'front-casino-overview',
        component: frontendCasinoOverviewPage,
        meta: { advertisement_placement_id: 2 },
    },
    {
        path: '/casino/overview/:id',
        name: 'front-casino',
        component: frontendCasinoPage,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/news',
        name: 'front-casino-news',
        component: frontendCasinoPageNews,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    // TODO add new page
    {
        path: '/casino/overview/news/:id',
        name: 'front-calendar-event-page',
        component: frontendCasinoNewsOnePage,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/sale',
        name: 'front-casino-sale',
        component: frontendCasinoPageSale,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/calendar',
        name: 'front-casino-calendar',
        component: frontendCasinoPageCalendar,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/reviews',
        name: 'front-casino-reviews',
        component: frontendCasinoPageReviews,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/contacts',
        name: 'front-casino-contacts',
        component: frontendCasinoPageContacts,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/restaurant',
        name: 'front-casino-restaurant',
        component: frontendCasinoPageRestaurant,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/overview/:id/hotel',
        name: 'front-casino-hotel',
        component: frontendCasinoPageHotel,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 10 },
    },
    {
        path: '/casino/top',
        name: 'front-casino-top',
        component: frontendCasinoTopPage,
        meta: { advertisement_placement_id: 2 },
    },
    {
        path: '/casino/rating',
        name: 'front-casino-rating',
        component: frontendCasinoRatingPage,
        meta: { advertisement_placement_id: 2 },
    },
    {
        path: '/casino/sale',
        name: 'front-casino-sales',
        component: frontendCasinoSalePage,
        meta: { advertisement_placement_id: 2 },
    },
    {
        path: '/casino/tours',
        name: 'front-casino-tours',
        component: frontendGameToursPage,
        meta: { advertisement_placement_id: 2 },
    },
    {
        path: '/casino/map',
        name: 'front-casino-map',
        component: frontendCasinoMapPage,
        meta: { advertisement_placement_id: 2 },
    },
];
