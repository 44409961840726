import Settings from '@/settings';
export default class Auth {
    static instance;
    userId;
    typeId;
    isLoggedIn = false;
    token = '';
    expiration = new Date();
    permissions = [];
    static logIn(id, typeId, token, expiration, permissions) {
        const self = this.getInstance();
        self.setToken(id, typeId, token, expiration, permissions);
        self.restoreSession();
    }
    static restore(login) {
        const loginFormData = new FormData();
        loginFormData.append('email', login);
        return fetch(Settings.apiUrl + 'users/api/request-password-reset', {
            body: loginFormData,
            method: 'post',
        }).then((response) => response.json());
    }
    static reset(login) {
        const loginFormData = new FormData();
        loginFormData.append('PasswordResetRequestForm[email]', login);
        return fetch(Settings.apiUrl + 'users/api/request-password-reset', {
            body: loginFormData,
            method: 'post',
        }).then((response) => response.json());
    }
    static remoteLogIn(login, password) {
        const loginFormData = new FormData();
        loginFormData.append('LoginForm[username]', login);
        loginFormData.append('LoginForm[password]', password);
        return fetch(Settings.apiUrl + 'users/api/login', {
            body: loginFormData,
            method: 'post',
        }).then((response) => response.json()).then((response) => {
            return new Promise((resolve, reject) => {
                if (!response.errors) {
                    const token = response.result.token;
                    const expired = response.result.expired;
                    const permissions = response.permissions;
                    const userId = response.userId;
                    const typeId = response.typeId;
                    Auth.logIn(userId, typeId, token, expired, permissions);
                    resolve(response);
                }
                else {
                    reject(response.errors);
                }
            });
        });
    }
    static logout() {
        const self = this.getInstance();
        self.removeLocalAuthData();
        self.redirectToMainPage();
    }
    static isLoggedIn() {
        const self = this.getInstance();
        return self.isTokenExists() && self.isTokenValid();
    }
    static token() {
        const self = this.getInstance();
        return self.token;
    }
    static userHasPermission(permissionName) {
        const self = this.getInstance();
        return self.permissions.includes(permissionName);
    }
    static getUserId() {
        const self = this.getInstance();
        return self.userId || 777;
    }
    static getUserType() {
        const self = this.getInstance();
        return self.typeId;
    }
    static setUserType(value) {
        const self = this.getInstance();
        self.typeId = value;
        localStorage.setItem('type-id', value);
    }
    static homeLink() {
        const self = this.getInstance();
        switch (self.typeId) {
            case 1:
                return '/default/profile';
            case 2:
                return '/company/profile';
            case 3:
                return '/company/profile';
            case 4:
                return '/company/profile';
            case 5:
                return '/default/profile';
            case 999:
                return '/admin';
        }
        return '';
    }
    static chatLink() {
        const self = this.getInstance();
        switch (self.typeId) {
            case 1:
                return '/default/chat';
            case 999:
                return '/admin/chat';
            case 2:
                return '/company/chat';
        }
        return '';
    }
    static areWeInRestictedArea() {
        const path = window.location.pathname;
        return /^\/admin/.test(path) || /^\/company/.test(path) || /^\/profile/.test(path);
    }
    constructor() {
        this.restoreSession();
    }
    static getInstance() {
        if (!Auth.instance) {
            Auth.instance = new Auth();
        }
        return Auth.instance;
    }
    restoreSession() {
        if (this.isTokenExists() && this.isTokenValid()) {
            this.isLoggedIn = true;
            this.userId = this.getLocalStorageUserId();
            this.typeId = this.getLocalStorageTypeId();
            this.token = this.getLocalStorageToken();
            this.expiration = this.getLocalStorageExpiration();
            this.permissions = this.getLocalStoragePermissions();
        }
        else if (Auth.areWeInRestictedArea()) {
            this.redirectToMainPage();
        }
    }
    redirectToMainPage() {
        window.location.pathname = '/';
    }
    getLocalStorageUserId() {
        return parseInt(localStorage.getItem('user-id'), 10);
    }
    getLocalStorageTypeId() {
        return parseInt(localStorage.getItem('type-id'), 10);
    }
    getLocalStorageToken() {
        return localStorage.getItem('token');
    }
    getLocalStorageExpiration() {
        return new Date(localStorage.getItem('expired'));
    }
    getLocalStoragePermissions() {
        return JSON.parse(localStorage.getItem('permissions'));
    }
    isTokenExists() {
        return !!localStorage.getItem('token');
    }
    removeLocalAuthData() {
        localStorage.removeItem('token');
        localStorage.removeItem('user-id');
        localStorage.removeItem('expired');
        localStorage.removeItem('permissions');
        localStorage.removeItem('type-id');
    }
    isTokenValid() {
        const expired = this.getLocalStorageExpiration().getTime();
        const now = Date.now();
        return expired > now;
    }
    setToken(id, typeId, token, expiration, permissions) {
        localStorage.setItem('user-id', `${id}`);
        localStorage.setItem('token', token);
        localStorage.setItem('expired', expiration);
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('type-id', `${typeId}`);
    }
}
