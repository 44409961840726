const frontendUserPage = () => import('@/components/frontend/pages/User/UserPage.vue');
const frontendUserPublicationPage = () => import('@/components/frontend/pages/User/UserPublicationPage.vue');
export default [
    {
        path: '/user/:id',
        name: 'front-user',
        component: frontendUserPage,
    },
    {
        path: '/user/:id/publications/:pid',
        name: 'front-user-publication',
        component: frontendUserPublicationPage,
    },
];
