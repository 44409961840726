const frontendLayout = () => import('@/components/frontend/layouts/BaseLayout.vue');
const frontendMainPage = () => import('@/components/frontend/pages/Main/MainPage.vue');
const frontendInfoPage = () => import('@/components/frontend/pages/About/InfoPage.vue');
const frontendAdvertisementPage = () => import('@/components/frontend/pages/About/AdvertisementPage.vue');
const frontendAboutRegistrationPage = () => import('@/components/frontend/pages/About/HowToRegisterPage.vue');
const frontendAboutEstimatesPage = () => import('@/components/frontend/pages/About/EstimatesPage.vue');
const frontendAboutSchoolPage = () => import('@/components/frontend/pages/About/SchoolPage.vue');
const frontendNewsPage = () => import('@/components/frontend/pages/News/NewsPage.vue');
const frontendSalesPage = () => import('@/components/frontend/pages/Sale/SalesPage.vue');
const frontendSalePage = () => import('@/components/frontend/pages/Sale/SalePage.vue');
const frontendRafflePage = () => import('@/components/frontend/pages/Raffles/RafflePage.vue');
const frontendRafflesPage = () => import('@/components/frontend/pages/Raffles/RafflesPage.vue');
const frontendReviewPage = () => import('@/components/frontend/pages/Reviews/ReviewPage.vue');
const frontendReviewsPage = () => import('@/components/frontend/pages/Reviews/ReviewsPage.vue');
const frontendConcertsPage = () => import('@/components/frontend/pages/Concerts/ConcertsPage.vue');
const frontendConcertPage = () => import('@/components/frontend/pages/Concerts/ConcertPage.vue');
const frontendSearchPage = () => import('@/components/frontend/pages/Search/SearchPage.vue');
// last 5.11.23
const frontendHelpPage = () => import('@/components/frontend/pages/Help/HelpPage.vue');
const frontendRules = () => import('@/components/frontend/pages/Help/Rules.vue');
const frontendTerms = () => import('@/components/frontend/pages/Help/Terms.vue');
// const frontendFaqPage = () => import('@/components/frontend/pages/About/FaqPage.vue'); // old
const frontendFaq = () => import('@/components/frontend/pages/Help/Faq.vue');
// about
const frontendAbout = () => import('@/components/frontend/pages/About/About.vue');
const frontendWayWeWork = () => import('@/components/frontend/pages/About/WayWeWork.vue');
const frontendJob = () => import('@/components/frontend/pages/About/Job.vue');
const frontendPartners = () => import('@/components/frontend/pages/About/Partners.vue');
const frontendContact = () => import('@/components/frontend/pages/About/Contact.vue');
import frontendGameTours from '@/router/frontendGameTours';
import frontendRestaurants from '@/router/frontendRestaurants';
import frontendPoker from '@/router/frontendPoker';
import frontendHotel from '@/router/frontendHotel';
import frontendCasino from '@/router/frontendCasino';
import frontendCalendar from '@/router/frontendCalendar';
import frontendBookmakers from '@/router/frontendBookmakers';
import frontendOnline from '@/router/frontendOnline';
import frontendSchool from '@/router/frontendSchool';
import frontendExperts from '@/router/frontendExperts';
import frontendUsers from '@/router/frontendUsers';
import frontendDirections from '@/router/frontendDirections';
export default {
    path: '/',
    component: frontendLayout,
    children: [
        ...frontendDirections,
        ...frontendGameTours,
        ...frontendRestaurants,
        ...frontendPoker,
        ...frontendHotel,
        ...frontendCalendar,
        ...frontendBookmakers,
        ...frontendOnline,
        ...frontendSchool,
        ...frontendExperts,
        ...frontendCasino,
        ...frontendUsers,
        {
            path: '',
            name: 'front-root',
            component: frontendMainPage,
        },
        {
            path: '/about',
            name: 'front-about',
            component: frontendAbout,
            meta: {
                // id: 3,
                id: 401,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/advertisement',
            name: 'front-advertisement',
            component: frontendAdvertisementPage,
            meta: {
                // id: 4,
                id: 403,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/the-way-we-work',
            name: 'front-the-way-we-work',
            component: frontendWayWeWork,
            meta: {
                id: 402,
                // id: 1493,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/partners',
            name: 'front-partners',
            component: frontendPartners,
            meta: {
                // id: 1494,
                id: 404,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/job',
            name: 'front-job',
            component: frontendJob,
            meta: {
                // id: 6,
                id: 405,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/contact',
            name: 'front-contact',
            component: frontendContact,
            meta: {
                // id: 7,
                id: 406,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/terms-of-use',
            name: 'front-terms-of-use',
            component: frontendTerms,
            meta: {
                // id: 11,
                id: 410,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/faq',
            name: 'front-faq',
            component: frontendFaq,
            meta: {
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/helpdesk',
            name: 'front-helpdesk',
            component: frontendInfoPage,
            meta: {
                // id: 9,
                id: 408,
                advertisement_placement_id: 19,
            },
        },
        // {
        //     path: '/help',
        //     name: 'front-help',
        //     component: frontendInfoPage,
        //     meta: { id: 8 },
        // },
        {
            path: '/rules',
            name: 'front-help2',
            component: frontendRules,
            meta: {
                id: 10,
                advertisement_placement_id: 19,
            },
        },
        {
            path: '/about/how-to-register',
            name: 'front-about-how-to-register',
            component: frontendAboutRegistrationPage,
        },
        {
            path: '/about/estimates',
            name: 'front-about-estimates',
            component: frontendAboutEstimatesPage,
        },
        {
            path: '/about/school',
            name: 'front-about-school',
            component: frontendAboutSchoolPage,
        },
        {
            path: '/search',
            name: 'front-search',
            component: frontendSearchPage,
        },
        {
            path: '/help',
            name: 'front-help',
            component: frontendHelpPage,
            meta: { advertisement_placement_id: 20 },
        },
        {
            path: '/news/',
            name: 'front-news',
            component: frontendNewsPage,
            meta: { advertisement_placement_id: 1 },
        },
        {
            path: '/sale/',
            name: 'front-sale',
            component: frontendSalesPage,
            meta: { advertisement_placement_id: 7 },
        },
        {
            path: '/sale/:id',
            name: 'front-sale-details',
            component: frontendSalePage,
            meta: { advertisement_placement_id: 16 },
        },
        {
            path: '/raffles/',
            name: 'front-raffles',
            component: frontendRafflesPage,
            meta: { advertisement_placement_id: 8 },
        },
        {
            path: '/raffles/:id',
            name: 'front-raffle',
            component: frontendRafflePage,
            meta: { advertisement_placement_id: 8 },
        },
        {
            path: '/reviews/',
            name: 'front-reviews',
            component: frontendReviewsPage,
            meta: { advertisement_placement_id: 9 },
        },
        {
            path: '/reviews/:id',
            name: 'front-review',
            component: frontendReviewPage,
            meta: { advertisement_placement_id: 9 },
        },
        {
            path: '/concerts/',
            name: 'front-concerts',
            component: frontendConcertsPage,
            meta: { advertisement_placement_id: 18 },
        },
        {
            path: '/concerts/:id',
            name: 'front-concert',
            component: frontendConcertPage,
            meta: { advertisement_placement_id: 14 },
        },
    ],
};
