const frontendSchoolPage = () => import('@/components/frontend/pages/School/SchoolPage.vue');
const frontendSchoolTopPage = () => import('@/components/frontend/pages/School/SchoolTopPage.vue');
const frontendSchoolOverviewPage = () => import('@/components/frontend/pages/School/SchoolOverviewPage.vue');
const frontendSchoolRatingPage = () => import('@/components/frontend/pages/School/SchoolRatingPage.vue');
export default [
    {
        path: '/school',
        name: 'front-school',
        component: frontendSchoolPage,
    },
    {
        path: '/school/top',
        name: 'front-school-top',
        component: frontendSchoolTopPage,
    },
    {
        path: '/school/overview',
        name: 'front-school-overview',
        component: frontendSchoolOverviewPage,
    },
    {
        path: '/school/rating',
        name: 'front-school-rating',
        component: frontendSchoolRatingPage,
    },
];
