const frontendPokerPage = () => import('@/components/frontend/pages/Poker/PokerPage.vue');
const frontendPokerTournamentPage = () => import('@/components/frontend/pages/Poker/PokerTournamentPage.vue');
const frontendPokerTournamentsPage = () => import('@/components/frontend/pages/Poker/PokerTournamentsPage.vue');
const frontendPokerOverviewPage = () => import('@/components/frontend/pages/Poker/PokerOverviewPage.vue');
const frontendPokerTopPage = () => import('@/components/frontend/pages/Poker/PokerTopPage.vue');
const frontendPokerRatingPage = () => import('@/components/frontend/pages/Poker/PokerRatingPage.vue');
const frontendPokerSalePage = () => import('@/components/frontend/pages/Poker/PokerSalePage.vue');
const frontendPokerMapPage = () => import('@/components/frontend/pages/Poker/PokerMapPage.vue');
const frontendPokerPageNews = () => import('@/components/frontend/pages/Poker/PokerPageNews.vue');
const frontendPokerPageSale = () => import('@/components/frontend/pages/Poker/PokerPageSale.vue');
const frontendPokerPageCalendar = () => import('@/components/frontend/pages/Poker/PokerPageCalendar.vue');
const frontendPokerPageReviews = () => import('@/components/frontend/pages/Poker/PokerPageReviews.vue');
const frontendPokerPageContacts = () => import('@/components/frontend/pages/Poker/PokerPageContacts.vue');
export default [
    {
        path: '/poker',
        name: 'front-poker',
        component: frontendPokerOverviewPage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/overview',
        name: 'front-poker-overview',
        component: frontendPokerOverviewPage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/overview/:id',
        name: 'front-poker-club',
        component: frontendPokerPage,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 11 },
    },
    {
        path: '/poker/overview/:id/news',
        name: 'front-poker-news',
        component: frontendPokerPageNews,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 11 },
    },
    {
        path: '/poker/overview/:id/sale',
        name: 'front-poker-sale',
        component: frontendPokerPageSale,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 11 },
    },
    {
        path: '/poker/overview/:id/calendar',
        name: 'front-poker-calendar',
        component: frontendPokerPageCalendar,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 11 },
    },
    {
        path: '/poker/overview/:id/reviews',
        name: 'front-poker-reviews',
        component: frontendPokerPageReviews,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 11 },
    },
    {
        path: '/poker/overview/:id/contacts',
        name: 'front-poker-contacts',
        component: frontendPokerPageContacts,
        meta: { menu: 'HeaderMenu', advertisement_placement_id: 11 },
    },
    {
        path: '/poker/top',
        name: 'front-poker-top-page',
        component: frontendPokerTopPage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/rating',
        name: 'front-poker-rating-page',
        component: frontendPokerRatingPage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/sale',
        name: 'front-poker-sale-page',
        component: frontendPokerSalePage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/sales/:id',
        name: 'front-poker-sale-details-page',
        component: frontendPokerSalePage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/tournaments/:id',
        name: 'front-poker-tournament',
        component: frontendPokerTournamentPage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/tournaments',
        name: 'front-poker-tournaments',
        component: frontendPokerTournamentsPage,
        meta: { advertisement_placement_id: 3 },
    },
    {
        path: '/poker/map',
        name: 'front-poker-map',
        component: frontendPokerMapPage,
        meta: { advertisement_placement_id: 3 },
    },
];
