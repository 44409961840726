import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '@/auth';
import frontendRouter from '@/router/frontend';
import adminRouter from '@/router/admin';
import userRouter from '@/router/user';
import companyRouter from '@/router/company';
const logout = () => import('@/components/backend/pages/Logout.vue');
const pageNotFound = () => import('@/components/frontend/pages/PageNotFound.vue');
Vue.use(VueRouter);
const routes = [
    frontendRouter,
    adminRouter,
    userRouter,
    companyRouter,
    {
        path: '/log-out',
        component: logout,
    },
    {
        path: '*',
        name: 'page404',
        component: pageNotFound,
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    // This hack is required because styles is not compatible between pages
    if (to?.meta?.requirePermission) {
        // if (to && to.meta && to.meta.requirePermission) {
        if (!Auth.userHasPermission(to.meta.requirePermission)) {
            alert('Доступ к разделу закрыт');
            return;
        }
    }
    if (to !== from && from.path !== '/' && !Auth.areWeInRestictedArea()) {
        window.location.href = to.path;
    }
    else {
        next();
    }
});
router.afterEach(() => {
    window.scrollTo(0, 0);
});
export default router;
